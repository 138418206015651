import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import Menu from './Menu.js'
import AirportPage from './AirportPage.js'

import {useEffect, useState} from "react";


function App() {
    const [activeAirport, setActiveAirport] = useState(null);
    const [airports, setAirports] = useState({});
    const [pdf, setPdf] = useState(null);

    const setAirport = (code) => {
        setActiveAirport(airports[code])
        // setPdf('https://pub-a9de26e13bf244daa61ea85cd9a6dcbf.r2.dev/charts/' + airports[code]['slug'] + '/' + airports[code]['charts'][0]['path'])
        // console.log(pdf)
    }

    useEffect(() => {
        fetch('https://pub-a9de26e13bf244daa61ea85cd9a6dcbf.r2.dev/airports.json')
            .then(response => response.json())
            .then(jsonData => setAirports(jsonData))
            .catch(error => console.error(error));
    }, []);


    return (
      <div className={'flex-container'}>
        <div className={'col menu'}>
            {activeAirport != null ? <AirportPage airport={activeAirport} setAirport={setActiveAirport} setPdf={setPdf} />:<Menu setAirport={setAirport}/> }

            {/*<Menu setAirport={setAirport}/>*/}
        </div>
        <div className={'col'}>
            {pdf != null ? <iframe className={'pdf-iframe'} src={pdf} />: null }
        </div>
      </div>
    );
}

export default App;
