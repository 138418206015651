import { Form, FormControl, InputGroup } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';

import './AirportPage.css';
import Card from "react-bootstrap/Card";
import CloseButton from 'react-bootstrap/CloseButton';

const PdfCard = ({ chart, updatePdf }) => {
    const handleClick = () => {
        updatePdf(chart['path'])
    };


    return (
        <div className="card" onClick={handleClick}>
            <Card className={'airport-card'} body>{chart['title']}</Card>
        </div>
    );
};


function AirportPage({airport, setAirport, setPdf}) {

    const handlePdfClick = (newPdf) => {
        setPdf('https://pub-a9de26e13bf244daa61ea85cd9a6dcbf.r2.dev/charts/' + airport['slug'] + '/' + newPdf)
    };

    const closeClick = () => {
        setAirport(null)
    }

    return (
        <div className={'airport-con'}>
            <div className={'top-con'}>
                {airport['code']}, {airport['name']} <CloseButton onClick={closeClick}/>
            </div>
            <div className={'search-results "overflow-auto'}>
                {airport['charts'].map((value) => (
                    <PdfCard key={value['path']} id={value['path']} chart={value} updatePdf={handlePdfClick}>{value['title']}</PdfCard>

                    // <div className="card" key={value['path']}>
                    //     <Card className={'airport-card'} body>{value['path']}</Card>
                    // </div>
                ))}
            </div>
        </div>
    );

}

export default AirportPage;
