import { Form, FormControl, InputGroup } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import React, { useState, useEffect } from 'react';

import './menu.css';


const AirportCard = ({ id, title, setAirport }) => {
    const handleClick = () => {
        setAirport(id)
    };


    return (
        <div className="card" onClick={handleClick}>
            <Card className={'airport-card'} body>{title}</Card>
        </div>
    );
};

function Menu({setAirport}) {
    const [data, setData] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        fetch('https://pub-a9de26e13bf244daa61ea85cd9a6dcbf.r2.dev/names.json')
            .then(response => response.json())
            .then(jsonData => setData(jsonData))
            .catch(error => console.error(error));
    }, []);

    useEffect(() => {
        const results = Object.entries(data).filter(([key, value]) =>
            value.toLowerCase().includes(searchTerm.toLowerCase()) && searchTerm);
        setSearchResults(results);
    }, [data, searchTerm]);

    const handleInputChange = event => {
        setSearchTerm(event.target.value);
    };


    return (
        <div className={'menu-con'}>
            <h2 className={'title'}>
                RussianCharts
            </h2>
            <hr/>
            <FormControl
                placeholder="Найти аэропорт"
                aria-label="Search"
                aria-describedby="basic-addon1"
                value={searchTerm}
                onChange={handleInputChange}
            />
            <div className={'search-results "overflow-auto'}>
                {searchResults.map(([key, value]) => (
                    <AirportCard key={key} id={key} title={value} setAirport={setAirport}>{value}</AirportCard>

                    // <Card className={'airport-card'} body key={key} onClick={() => setActiveAirport(key)}>{value}</Card>
                ))}
            </div>
        </div>
    );

}

export default Menu;
